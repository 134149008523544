import React from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import StarIcon from "@material-ui/icons/StarBorder";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const tiers = [
  // {
  //   title: "Free",
  //   price: "0",
  //   description: ["Daily Calendar Alerts"],
  //   buttonText: "Sign up for free",
  //   buttonVariant: "outlined",
  // },
  {
    title: "Court Calendar Robot",
    subheader: "Daily Email Alerts",
    price: "TBD",
    description: [
      "Daily Email Alerts",
      "Calendar Monitoring",
      "Statistics",
    ],
    buttonText: "Sign Up For Free Trial",
    buttonVariant: "outlined",
  },
  {
    title: "Basic",
    subheader: "Case Managment",
    price: "TBD",
    description: [
      "Court Calendar Robot +",
      "Time Keeping",
      "Case Management",
      "Task Mangement",
      "Calendar Managment",
      "Case Alerts",
      "CJA Voucher Integration",
    ],
    buttonText: "Sign Up For Free Trial",
    buttonVariant: "contained",
  },
  {
    title: "Pro",
    subheader: "Billing and Case Automation",
    price: "TBD",
    description: [
      "Time Keeping",
      "Case Management",
      "Local Court Rules",
      "Calendar Management and Alerts",
      "Automated Client Texting and Emails",
      "Automated Form Creation",
      "Automated Motion Drafting",
      "Discovery Cataloging",
      "Case Timelines",
      "Trial File Organizer",
      "Case Outlining ",
      "Docket Mirroring",
      "Investigation Tracking",
      "Guideline Sentence Calculation",
    ],
    buttonText: "Coming Soon!",
    buttonVariant: "contained",
  },
];
const footers = [
  // {
  //   title: "Company",
  //   description: ["Team", "History", "Contact us", "Locations"],
  // },
  {
    title: "Features",
    description: [
      { title: "UNDER CONSTRUCTION", href: "#" },
      // { title: "Timeline", href: "#" },
      // { title: "Voucher Integration", href: "#" },
    ],
  },
  {
    title: "Resources",
    description: [
      // { title: "DC CJA Members", href: "#" },
      { title: "UNDER CONSTRUCTION", href: "#" },
    ],
  },
  {
    title: "Legal",
    description: [
      { title: "Terms of Service", href: "/terms" },
      { title: "Privacy Policy", href: "/privacy" },
    ],
  },
];

export default function Home() {
  const classes = useStyles();

  return (
    <React.Fragment>
      {/* Hero unit */}
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          CourtRobot
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="textSecondary"
          component="p"
        >
          Organize, stay informed, and get paid for your time. The CourtRobot is
          your personal legal assistant.
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === "Enterprise" ? 12 : 6}
              md={4}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{ align: "center" }}
                  action={tier.title === "Pro" ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      /mo
                    </Typography>
                  </div>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button
                    href={"/signup"}
                    fullWidth
                    variant={tier.buttonVariant}
                    color="primary"
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* Footer */}
      <Container maxWidth="md" component="footer" className={classes.footer}>
        <Grid container spacing={4} justify="space-evenly">
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                {footer.title}
              </Typography>
              <ul>
                {footer.description.map((item) => (
                  <li key={item.title}>
                    <Link
                      href={item.href}
                      variant="subtitle1"
                      color="textSecondary"
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* End footer */}
    </React.Fragment>
  );
}
