// import { red, indigo } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
import blueGrey from "@material-ui/core/colors/blueGrey";
import indigo from "@material-ui/core/colors/indigo";
// import green from "@material-ui/core/colors/green";

// If you do not use @material-ui/core/color/..
// then you must provide the main color.  You may also
// provide the light and dark themes as well.

export const lightTheme = createMuiTheme({
  palette: {
    // primary: {
    //   //   main: "#556cd6",
    //   main: "#ff0080",
    //   light: "#ff0080",
    //   dark: "#556cd6"
    // },
    primary: blueGrey,
    // secondary: {
    //   main: "#19857b",
    // },
    secondary: indigo,
  },
});

// A custom theme for this app
export const darkTheme = createMuiTheme({
  ...lightTheme,
  palette: {
    // primary: blueGrey,
    // secondary: green,
    secondary: indigo,
    primary: blueGrey,
    type: "dark",
  },
});
