import React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

// import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

// const useStyles = makeStyles((theme) => ({
//   root: {},
// }));

export default function Verify(props) {
  //   const classes = useStyles();
  //   console.log(props.location.search.slice(7));
  //   const search = props.location.search; // ?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.
  const token = props.location.search.slice(7);
  console.log(token);
  const [verified, setVerified] = React.useState(false);

  const [verify, { loading, error }] = useMutation(VERIFY, {
    update(_, { data: { verifyEmail: userData } }) {
      console.log(userData);
      setVerified(userData.emailVerified);
    },
    onError(err) {
      console.log("Form Error");
      //   setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
  });

  React.useState(() => {
    verify({
      variables: {
        token: token,
      },
    });
  }, []);

  const redirectLink = `https://mybot.courtrobot.com/login`;

  React.useEffect(() => {
    if (verified) {
      setTimeout(() => {
        window.location.replace(redirectLink);
      }, 2000);
    }
  }, [verified, redirectLink]);

  if (loading) {
    return <p>We are trying to verify your email. One moment please ...</p>;
  }

  if (error) {
    console.log(error);
    return (
      <Container component="main" maxWidth="xs">
        <Typography variant="h6">An error occured.</Typography>
        {error.message}
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="sm">
      {verified ? (
        <React.Fragment>
          <Typography variant="h3">
            Success! Your email has been verified.
          </Typography>
          <Typography variant="h4">
            You will be automatically redirected in a moment. Or you can click{" "}
            <Link href={redirectLink} variant="h4">
              here
            </Link>{" "}
            to login now.
          </Typography>
        </React.Fragment>
      ) : (
        <Typography>
          There was a problem. Your email has not been verified.
        </Typography>
      )}
    </Container>
  );
}

const VERIFY = gql`
  mutation verifyEmail($token: String!) {
    verifyEmail(token: $token) {
      id
      email
      emailVerified
      name {
        first
        last
      }
      createdAt
    }
  }
`;
