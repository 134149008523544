import React, { useState } from "react";

import { ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter, Route, Link } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";

import CssBaseline from "@material-ui/core/CssBaseline";

import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import "fontsource-roboto"; // Material UI Font
import { lightTheme, darkTheme } from "./theme";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Copyright from "./components/copyright";
import Success from "./pages/success";
import Signup from "./pages/signup";
import Pricing from "./pages/pricing";
import Home from "./pages/home";
import VerifyEmail from "./pages/verifyEmail";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import { Switch } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const classes = useStyles();

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <BrowserRouter>
        <CssBaseline />
        <AppBar
          position="static"
          color="primary"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar className={classes.toolbar}>
            <Typography
              variant="h6"
              color="inherit"
              noWrap
              className={classes.toolbarTitle}
            >
              {" "}
              <Link
                to={"/"}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                CourtRobot
              </Link>
            </Typography>

            <nav>
              {/* <Link
              variant="button"
              color="textPrimary"
              href="#"
              className={classes.link}
            >
              Features
            </Link> */}
              {/* <Link
              variant="button"
              color="textPrimary"
              href="#"
              className={classes.link}
            >
              Enterprise
            </Link> */}
              {/* <Link
              variant="button"
              color="textPrimary"
              href="#"
              className={classes.link}
            >
              Support
            </Link> */}
            </nav>
            <FormControlLabel
              control={
                <Switch
                  checked={darkMode}
                  onChange={() => setDarkMode(!darkMode)}
                />
              }
              // label="Dark Mode"
            />
            <Button
              href="/signup"
              color="default"
              variant="contained"
              className={classes.link}
            >
              Sign Up
            </Button>
            {/* <Button
              href="https://mybot.courtrobot.com/login"
              color="default"
              variant="contained"
              className={classes.link}
            >
              Login
            </Button> */}
          </Toolbar>
        </AppBar>

        <Route exact path="/" component={Home} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/pricing" component={Pricing} />
        <Route exact path="/success" component={Success} />
        <Route exact path="/verify-email" component={VerifyEmail} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/terms" component={Terms} />
        <Box mt={5}>
          <Copyright />
        </Box>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
