import React from "react";

import Container from "@material-ui/core/Container";

function Success(props) {
  // console.log(props);

  const user = props.location.state.user;
  return (
    <Container component="main" maxWidth="sm">
      <div className="form-container">
        <h3>
          Thanks, {user.name.first} {user.name.last}. You are almost done
          creating your new CourtRobot account.
        </h3>

        <h4>
          An email has been sent to{" "}
          <span style={{ color: "blue" }}>{user.email}</span>. Please check your
          email and click on the link to verify your email address. Once
          verified, you will have completed the account setup and will be able
          to log into your new CourtRobot account. If you do not see the email,
          please check your spam filter. The email was sent from
          mail@courtrobot.com.
        </h4>
      </div>
    </Container>
  );
}

export default Success;
