import React from "react";

import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp(props) {
  const classes = useStyles();

  const [errors, setErrors] = React.useState({});

  const [values, setValues] = React.useState({
    email: "",
    password: "",
    confirmPassword: "",
    first: "",
    last: "",
  });

  const onChange = (event) => {
    event.preventDefault();
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const [addUser] = useMutation(REGISTER_USER, {
    update(_, { data: { register: userData } }) {
      props.history.push({
        pathname: "/success",
        state: { user: userData },
      }); // Sends user back to home page
    },
    onError(err) {
      console.log("Form Error");
      setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
  });

  const onSubmit = (event) => {
    event.preventDefault();
    addUser({
      variables: {
        ...values,
        first: values.first.trim(),
        last: values.last.trim(),
        email: values.email.trim().toLowerCase(),
        password: values.password.trim(),
        confirmPassword: values.confirmPassword.trim(),
      },
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Typography component="h1" variant="h6">
          *Sign up is currently restricted to invited beta users.
        </Typography>
        <form onSubmit={onSubmit} className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="first"
                variant="outlined"
                required
                fullWidth
                id="first-name"
                label="First Name"
                onChange={onChange}
                value={values.first}
                error={errors.first ? true : false}
                helperText={errors.first ? errors.first : ""}
                // autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="last"
                onChange={onChange}
                value={values.last}
                error={errors.last ? true : false}
                helperText={errors.last ? errors.last : ""}
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                onChange={onChange}
                autoComplete="email"
                value={values.email}
                error={errors.email ? true : false}
                helperText={errors.email ? errors.email : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={onChange}
                autoComplete="current-password"
                value={values.password}
                error={errors.password ? true : false}
                helperText={errors.password ? errors.password : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={onChange}
                variant="outlined"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                value={values.confirmPassword}
                error={errors.confirmPassword ? true : false}
                helperText={
                  errors.confirmPassword ? errors.confirmPassword : ""
                }
                // autoComplete="current-password"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="https://mybot.courtrobot.com" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
        {Object.keys(errors).length > 0 && (
          <div className="ui error message">
            <ul className="list">
              {Object.values(errors).map((value) => (
                <li key={value}>{value}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Container>
  );
}

const REGISTER_USER = gql`
  mutation register(
    $email: String!
    $password: String!
    $confirmPassword: String!
    $first: String!
    $last: String!
  ) {
    register(
      registerInput: {
        email: $email
        password: $password
        confirmPassword: $confirmPassword
        first: $first
        last: $last
      }
    ) {
      id
      email
      name {
        first
        last
      }
      createdAt
      token
    }
  }
`;
