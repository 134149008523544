import React from "react";
import App from "./App";
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createHttpLink } from "apollo-link-http";
import { ApolloProvider } from "@apollo/react-hooks";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";

// import { setContext } from "apollo-link-context";

const GRAPHQL_URI =
  process.env.REACT_APP_GRAPHQL_URI || "http://localhost:5000/graphql";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = createHttpLink({
  uri: GRAPHQL_URI,
  credentials: "include",
});

// const authLink = setContext(() => {
//   // const token = localStorage.getItem("jwtToken");
//   const token = getAccessToken();
//   return {
//     headers: {
//       Authorization: token ? `Bearer ${token}` : "",
//     },
//   };
// });

const httpLinkWithErrorHandling = ApolloLink.from([errorLink, httpLink]);

// const client = new ApolloClient({
//   link: authLink.concat(httpLinkWithErrorHandling),
//   cache: new InMemoryCache(),
// });

const client = new ApolloClient({
  link: httpLinkWithErrorHandling,
  cache: new InMemoryCache(),
});

export default (
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);
